import moment from 'moment'

/* global Routing */
const displayNotification = function (title, message, options) {
  var settings = $.extend({
    bgClass: '',
    delay: 5000
  }, options)

  let template
  template = `
    <div class="toast" data-delay="{{ settings.delay }}" role="alert" aria-live="assertive" aria-atomic="true">
        <div class="toast-header {{ settings.headerClass }}">
            <strong class="mr-auto text-white">{{ title }}</strong>
            <button type="button" class="ml-2 mb-1 close" data-dismiss="toast" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="toast-body">
            {{ message }}
        </div>
    </div>
    `

  template = template
    .replace('{{ title }}', title)
    .replace('{{ message }}', message)
    .replace('{{ settings.headerClass }}', settings.headerClass)
    .replace('{{ settings.delay }}', settings.delay)

  const toast = $(template)
  toast.appendTo('#toast-container')
  toast.toast('show')
  toast.on('hidden.bs.toast', function () {
    this.remove()
  })
}

const isValidJSON = function (txt) {
  try {
    JSON.parse(txt)
    return true
  } catch (err) {
    return false
  }
}

const isWeekend = function (date) {
  return (['6', '7'].indexOf(date.format('E')) !== -1)
}

const initialiserTimepicker = function () {
  $('.timepicker').each(function (index, element) {
    const $element = $(element)

    let step = 15
    if ($element.data('step-time')) {
      step = $element.data('step-time')
    }

    const settings = {
      useSelect: true,
      className: 'form-control',
      timeFormat: 'H:i',
      wrapHours: false,
      step: step,
      minTime: $element.data('min-time'),
      maxTime: $element.data('max-time')
    }
    $element.timepicker(settings)
  })
}

const initialiserToggle = function () {
  $('body .toggle').each(function (i, elm) {
    const id = $(elm).attr('id')
    $('#' + id).bootstrapToggle()
  })
}

const confirm = function (options, onValidation, onCancel) {
  // Remove previous modal
  $('body #modalconfirm').remove()
  const modal = `<!-- Modal -->
    <div class="modal fade" id="modalconfirm" tabindex="-1" role="dialog" aria-labelledby="modalconfirmLabel" aria-hidden="false">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="modalconfirmLabel">Modal title</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body" id="modalbodyConfirm"></div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary" id="modalconfirmCancel" data-dismiss="modal">Annuler</button>
          <button type="button" id="modalconfirmOk" class="btn btn-secondary" data-dismiss="modal">Ok</button>
        </div>
      </div>
    </div>
  </div>`
  // append new modal
  $('body').append(modal)

  $('#modalconfirmLabel').html(options.title)
  $('#modalbodyConfirm').html('<div id="div1">' + options.content + '</div>')
  $('#modalconfirm').modal('show')
  $('#modalconfirmOk').on('click', function () {
    onValidation()
  })
  $('#modalconfirmCancel').on('click', function () {
    onCancel()
  })
}

const confirmSimple = function (options) {
  $('#modalLabel').html(options.title)
  $('#modalbody').html(options.content)
  $('#modal').modal('show')
}

const loadPanier = function ($elm) {
  const structure = $('body').data('structure')

  if (!structure) {
    return
  }

  const route = Routing.generate('reservation_panier_count', {
    st_slug: structure
  })
  $.get({
    url: route,
    success: function (data) {
      $($elm).html(data.nb)
      if (!$elm.is(':visible')) {
        $elm.show()
      }
    },
    error: function (e) {
      console.log('Une erreur est survenue pendant l\'initialisation du panier')
    }
  })
}

const loadContent = function (container, url, takePeriod = false) {
  $(container).html(funcs.loadingWrapper(['family', 'facture', 'inscription', 'infoPratique', 'alerte'], 5, 0, 0, 'spinner'))
  url = url.trim()

  if (takePeriod) {
    const $start = $('body #start_at')
    const $end = $('body #end_at')
    let hasAppenningParam = false
    if ($start.length > 0) {
      const vStart = $start.val()
      if (vStart !== undefined && vStart !== '') {
        url += '?startAt=' + vStart
        hasAppenningParam = true
      }
    }

    if ($end.length > 0) {
      const vEnd = $end.val()
      if (vEnd !== undefined && vEnd !== '') {
        if (hasAppenningParam) {
          url += '&'
        } else {
          url += '?'
        }
        url += 'endAt=' + vEnd
      }
    }
  }

  $(container).load(url, function () {
    funcs.init()
  })
}

const init = function () {
  // Init select2
  let select2DefaultPlaceholder = 'Sélectionner une valeur'
  $('body .select2.form-control').each(function (i, elm) {
    const $select = $(elm)
    if ($select.hasClass('ready')) {
      return
    }
    $select.addClass('ready')
    const selectPlaceholder = $select.data('placeholder')
    if (selectPlaceholder !== undefined) {
      select2DefaultPlaceholder = selectPlaceholder
    }
    $select.select2({
      placeholder: select2DefaultPlaceholder,
      theme: 'bootstrap4',
      language: 'fr'
    })
  })

  funcs.initialiserToggle()
}

const loadingWrapper = function (color = 'primary', multiple = 1, mx = 0, mt = 0, type = null) {
  let wrapper = ''
  let marginClass = (mx === 0 || mt === 0) ? 'm-auto' : 'mx-' + mx + ' mt-' + mt
  if (mx !== 0) {
    marginClass = 'my-auto mx-' + mx
  }
  if (mt !== 0) {
    marginClass = 'mx-auto mt-' + mt
  }

  let colorTxt = ''
  if (type == null) {
    for (var i = 0; i < multiple; i++) {
      if (Array.isArray(color)) {
        colorTxt = color[Math.floor(Math.random() * Math.floor(color.length))]
      } else {
        colorTxt = color
      }

      wrapper += `
              <div class="spinner-grow tools-loadingWrapper spinner-grow-sm ` + marginClass + ` bg-` + colorTxt + `">
              </div>
          `
    }
  }

  if (type === 'spinner') {
    if (Array.isArray(color)) {
      colorTxt = color[Math.floor(Math.random() * Math.floor(color.length))]
    } else {
      colorTxt = color
    }
    wrapper = `<div class="col-12 my-3"><div class="row justify-content-center">
                      <div class="spinner-border text-` + colorTxt + ` ` + marginClass + `"></div>
                  </div></div>`
  }

  return wrapper
}

const appendModal = function (title, content) {
  const ts = moment().unix()
  const modalId = Math.floor(Math.random() * Math.floor(ts))
  const modalWrapper = `
  <div class="modal fade" id="modal-` + modalId + `" tabindex="-1" role="dialog" aria-labelledby="modalLabel" aria-hidden="true">
      <div class="modal-wrapper" role="document">
          <div class="modal-content">
              <div class="modal-header">
                  <h5 class="modal-title" id="modalLabel">
                      ` + title + `
                  </h5>
                  <button type="button" class="close close-modal" data-modal="modal-` + modalId + `" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                  </button>
              </div>
              <div class="modal-body">
                  ` + content + `
              </div>
              <div class="modal-footer">
                  <button type="button" class="btn btn-secondary close-modal" data-modal="modal-` + modalId + `" data-dismiss="modal">
                      Fermer
                  </button>
              </div>
          </div>
      </div>
  </div>
  `

  $('body').append(modalWrapper)

  $('body #modal-' + modalId).modal({
    show: true,
    focus: true,
    keyboard: true
  })

  $('body').on('hidden.bs.modal', '#modal-' + modalId, function (e) {
    // const $modal = $(this)
    // console.log('modal is hidden #modal-' + modalId)
    $(this).remove()
  })
}

const loadModal = function (url) {
  let response = null
  // if ($('.modal').length > 0) {
  //   return
  // }

  $.get({
    url: url,
    processData: false,
    contentType: false,
    success: function (data) {
      // console.log('request as receipt with data : ', data)
      response = data
      funcs.appendModal(response.title, response.content)
    }
  })
}

const funcs = {
  displayNotification,
  isValidJSON,
  isWeekend,
  initialiserTimepicker,
  initialiserToggle,
  confirm,
  confirmSimple,
  loadPanier,
  loadContent,
  init,
  loadingWrapper,
  appendModal,
  loadModal
}

export default funcs
